<template>
    <div v-show="user_preview_modal" class="min-h-screen w-full bg-black bg-opacity-50 fixed top-0 left-0 z-50 flex justify-center items-center">
        <div class=" w-[60vw]  bg-white dark:bg-gray-800 rounded-lg flex flex-col gap-3 justify-between items-start p-5">
            <h3 class="font-bold text-xl mt-3">Submitted KYC Documents</h3>
            <div v-if="selected_KYC" class="min-h-[400px] max-h-[50vh] w-full overflow-y-auto">
                <!-- {{ selected_KYC }} -->
                
                <div class="flex flex-col gap-3">
                    <div class="flex flex-col">
                        <span>Selfie Image:</span>
                        <img :src="selected_KYC.selfie_photo" class=" w-[200px]"/>
                    </div>
                    
                    <div class="flex flex-col">
                        <span>NIN Slip</span>
                        <img :src="selected_KYC.NIN_slip" class=" w-[500px]"/>
                    </div>
                    <!-- {{  selected_user }} -->
                </div>
            </div>
         
           <div class="flex flex-row justify-between items-end w-full">
                <button @click="user_preview_modal = !user_preview_modal" class="btn !bg-red-500">exit</button>
                <button :disabled="verifying_user" @click="markUserAsVerified(selected_user._id)" class="btn">
                    <i class="bi bi-patch-check-fill mr-1"></i>
                    <span v-if="verifying_user">working...</span>
                    <span v-else>Verify User</span>
                </button>
           </div>
        </div>
    </div>
    <div>
        <h1 class="text-3xl font-bold">User KYC Approval</h1>
        <div class="w-full h-full flex items-center justify-center" v-if="loading">
            <SpinnerComponent/>
        </div>
        <div v-else  class="flex flex-col gap-3 mt-12 p-5">
           <!-- <div> {{ request.user }}</div> -->
           <div v-for="(request, index) in all_requests" :key="index" class="relative flex flex-row gap-3 border rounded-2xl p-3">
                <img v-if="request.user.profile.image_url" :src="request.user.profile.image_url" class=" w-[100px] rounded-xl"/>
                <div v-else class=" size-[100px] text-4xl font-bold rounded-xl flex justify-center items-center bg-tz_blue text-white">{{ request.user.firstname[0] }}{{  request.user.lastname[0] }}</div>
                <div class="flex flex-col">
                    <span>{{  request.user.username }} - {{  request.user.role }}</span>
                    <span>{{  request.user.title }}</span>
                    <span>{{  request.user.profile.title }}</span>
                    <span>{{  request.user.profile.social }} {{  request.user.profile.company_link }} | {{  request.user.profile.phone }}</span>
                    <!-- <span>{{  request.user.profile.location }}</span> -->
                </div>
                <button @click="previewUser(request.kyc.documents, request.user)" class=" absolute right-2 bottom-2 top-auto size-[40px] rounded-full flex justify-center items-center bg-blue-500 text-white">
                    <i class="bi bi-box-arrow-in-up-right"></i>
                </button>

               <!--  <h3 class="font-bold text-xl mt-3">Submitted KYC Documents</h3>
                <div class="flex flex-row gap-3">
                        <div class="flex flex-col">
                            <span>Selfie Image:</span>
                            <img :src="request.kyc.documents.selfie_photo" class=" w-[200px]"/>
                        </div>
                        
                        <div class="flex flex-col">
                            <span>NIN Slip</span>
                            <img :src="request.kyc.documents.NIN_slip" class=" w-[500px]"/>
                        </div>
                        <label>
                            Verify User
                            <input type="checkbox">
                        </label>
                </div> -->

           </div>
           
        </div>
        <div class="w-full h-[400px] flex justify-center items-center" v-if="!loading && all_requests.length == 0">No new KYC requests for now</div>
    </div>
</template>

<script>
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import axios from 'axios';


    export default {
        name: "AdminUserKYCView",
        components: {
            SpinnerComponent
        },
        data(){
            return{
                headers: {
                    Authorization: `JWT ${localStorage.getItem('life-gaurd')}`
                },

                all_requests: [],
                loading: null,
                see_more: [],
                selected_KYC: [],
                selected_user: [],
                user_preview_modal: false,

                verifying_user: false,
            }
        },

        methods: {
            async getAllKYCRequests(){
                try{
                    this.loading = true;
                    const response = await axios.get("/admin/KYC/all_requests", { headers:this.headers});
                    this.all_requests = response.data.all_requests;
                    this.loading = false;
                }catch(error){
                    console.log("error getting KYC requests: ", error);
                }
            },

            previewUser(KYC, user){
                this.selected_KYC = KYC;
                this.selected_user = user;
                this.user_preview_modal = true;
            },

            async markUserAsVerified(user_id){
                console.log('trying to verify ID: ', user_id)
                this.verifying_user = true
                try{
                    const response = await axios.post(`/admin/verifications/${user_id}/mark_verified`, {},{ headers: this.headers });
                    console.log("verifying user: ", response);
                    this.verifying_user = false;
                    this.user_preview_modal = false;
                    this.getAllKYCRequests();
                }catch(error){
                    console.log("error verifying user: ", error);
                    this.verifying_user = false
                }
            }
        },

        created(){
            this.getAllKYCRequests();
        }
    }
</script>

<style scoped>

</style>