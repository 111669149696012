<template>
    <!-- TOAST -->
        <Toast/>
    <!-- ***** -->
   
   


    <PageTitle>Settings</PageTitle>
    <div class="h-full flex flex-col relative">
        <FullPageLoading v-if="loading"/>
       
        <div class=" tab flex ps-2 flex-row flex-wrap gap-2 bg-white dark:bg-[#1F2A36] border-b dark:border-gray-500 sticky top-0">
            <!-- <button @click="active_tab = 1" :class="active_tab == 1 ? 'active_tab':''" class="tab_button">Appearance & theme</button> -->
            <button @click="active_tab = 2" :class="active_tab == 2 ? 'active_tab':''" class="tab_button">Profile & Account</button>
            <button @click="active_tab = 4" :class="active_tab == 4 ? 'active_tab':''" class="tab_button">ID & Verification</button>
            <button @click="active_tab = 3" :class="active_tab == 3 ? 'active_tab':''" class="tab_button">Notifications</button>
            <!-- <button @click="active_tab = 4" :class="active_tab == 4 ? 'active_tab':''" class="tab_button">Payment method</button> -->
            
        </div>


        <div class=" flex flex-col p-3 gap-5">
            

            <div v-if="active_tab == 2" class="section">
                <form @submit.prevent="updateUserData" class=" mt-3">
                    <h1 class="font-bold mb-3 text-lg">General Information</h1>
                    <div class="flex flex-row gap-3 mb-3">
                        <div class="w-3/6 flex flex-col">
                            <label for="firstname">firstname</label>
                            <!-- <input class="form_input" type="name" name="firstname" id="firstname" :value="user.firstname" disabled>? -->
                            <div class="form_input">{{ user.firstname }}</div>
                        </div>

                        <div class="w-3/6 flex flex-col">
                            <label for="firstname">lastname</label>
                            <!-- <input class="form_input" type="name" name="lastname" id="lastname" :value="user.lastname" disabled> -->
                            <div class="form_input">{{ user.lastname }}</div>
                        </div>
                    </div>
                    <div class="flex flex-col w-full">
                        <label for="email">Email</label>
                        <input class="form_input" type="email" name="email" id="email" :value="user.email">
                    </div>
                    <div class="flex flex-col w-full mt-3">
                        <label for="email">Phone Number</label>
                        <input v-if="user.profile" class="form_input" type="phone" name="phone" id="phone" v-model="user.profile.phone">
                    </div>
                    <button class="btn mt-3" type="submit">Update</button>
                </form>

          
                
                <form class="" @submit.prevent="updatePassword">
                    <h1 class="font-bold mb-3 text-lg mt-3">Password & Security</h1>
                    <div class="flex flex-col max-w-sm">
                        <label for="email">Current Password</label>
                        <input class="form_input" type="password" name="password" id="password" v-model="security.password" required>
                    </div>

                    <div class=" relative flex flex-col gap-3 justify-center max-w-sm mt-3">

                        <div v-if='passHint && passwordValidation.errors.length > 0' class='hints relative pt-6'>
                            <button @click="{passHint = !passHint; passwordValidation.errors.length = 0}" type="button" class=" absolute top-2 right-2"><i class="bi bi-x-lg"></i></button>
                            <small class="text-orange-600" v-for='error in passwordValidation.errors'>
                                <i class="bi bi-exclamation-circle"></i> {{ error }}<br/>
                            </small>
                        </div>

                        <div class="tz_form_control">
                            <label for="password">New password</label>
                            <input @click="passHint = true" class="form_input" type="password" name="password" id="password" placeholder="a very strong password" v-model="security.new_password" required>
                        </div>
                        <div class="tz_form_control">
                            <label for="password">New password confirmation</label>
                            <input class="form_input" type="password" name="password_confirmation" id="password_confirmation" placeholder="Confirm your password" v-model="security.new_password_confirmation" required>
                        </div>
                    </div>


                    <div class="text-red-500 py-3" type="danger" v-if="password_errors">
                        <i class="bi bi-exclamation-triangle-fill mr-3"></i>
                        <span>{{  password_errors }}</span>
                    </div>
                    <button type="button" @click="updatePassword" class="btn mt-3" :disabled="!passwordValidation.valid">Change Account Password</button>
                </form>


                <!-- DELETE ACCOUNT -->
                <div class=" mt-3 pb-3">
                    <h1 class="font-bold mb-3 text-lg mt-3">Account & Profile</h1>
                    <p class=" text-red-700 bg-red-500 bg-opacity-10 rounded-lg p-3">
                    <strong>Warning</strong><br/>
                    <span>Deleting your account cannot be undone. Any personally identifiable information will be deleted and any in-progress data will be lost.</span>
                    </p>
                    <button disabled type="submit" class=" bg-red-500 text-white hover:bg-red-700 rounded-md p-3 mt-3">Delete Account</button>
                </div>
            </div>

            <form @submit.prevent="updateUserData" v-if="active_tab == 3" class="section mb-3">
                <h1 class="font-bold mb-3 text-lg">Notification Settings</h1>
                <!-- <div class=" flex flex-row items-center justify-between justify-center">
                    <div class="flex flex-col">
                        <span class="font-bold">Contract Notifications</span>
                        <span class="text-sm w-72 text-gray-400">Allow ApexTeks send you email notifications for contract actions and updates.</span>
                    </div>
                    
                    <label class="inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" class="sr-only peer" @change="updateUserData" v-model="settings.notifications.contracts">
                        <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                </div> -->

                <div class=" flex flex-row items-center justify-between justify-center">
                    <div class="flex flex-col">
                        <span class="font-bold">Message Notifications</span>
                        <span class="text-sm w-72 text-gray-400">Allow ApexTeks send you email notifications for messages.</span>
                    </div>
                    
                    <label class="inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" class="sr-only peer" @change="updateUserData" v-model="settings.notifications.messages">
                        <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                </div>

                <div class=" flex flex-row items-center justify-between justify-center">
                    <div class="flex flex-col">
                        <span class="font-bold">Email Notifications</span>
                        <span class="text-sm w-72 text-gray-400">Allow ApexTeks send you email notifications for updates and activities related to your account.</span>
                    </div>
                    
                    <label class="inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" class="sr-only peer" @change="updateUserData" v-model="settings.notifications.emails">
                        <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                </div>
            </form>

             <!-- ID & VERIFICATION -->
             <form @submit.prevent="verifyUserNow"  v-if="active_tab == 4" class="section mb-3">
                
                <div class="mt-3">
                <!-- {{ verificationStatus }} -->
                    <h1 class="font-bold mb-3 text-xl capitalize">Identity verification - {{  verificationStatus }}</h1>
                    <div class="flex border dark:border-gray-600 rounded-xl p-5 flex-col">
                        <h3 class=" font-bold text-lg">Start your verification</h3>
                        <div class="flex flex-row">
                            <div>
                                <div></div>
                            </div>
                            <div class="relative flex flex-col gap-8 mt-8 w-full">
                                <div class="flex flex-row gap-3" v-for="step in verification_steps">
                                    <div :class="verificationStatus == 'verified' ? 'bg-green-500':'bg-blue-500'" class=" !size-8 min-w-[32px] rounded-full text-white flex justify-center items-center">
                                        <i v-if="verificationStatus == 'pending'" class="bi bi-exclamation"></i>
                                        <i v-if="verificationStatus == 'in progress'" class="bi bi-arrow-clockwise"></i>
                                        <i v-if="verificationStatus == 'verified'" class="bi bi-check"></i>
                                    </div>
                                    
                                    <div class="flex flex-col">
                                        <span>{{ step.title }}</span>
                                        <small>{{ step.subtitle }}</small>
                                    </div>
                                </div>
                                <div v-if="verificationStatus == 'pending'" class=" w-full flex flex-row justify-end items-center">
                                    <RouterLink :to="`/KYC/${user._id}/verification`">
                                        <button class="btn">Continue</button>
                                    </RouterLink>
                                </div>
                            </div>
                        </div>
                    </div>

                                  
                </div>
            </form>

          

            <div v-if="active_tab == 5" class="section mb-3">
                
              
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import JobDetailCard from '@/components/JobDetailCard.vue';
import TemplateView from '../TemplateView.vue';
import ContractStatus from '@/components/ContractStatus.vue';
import FullPageLoading from '@/components/FullPageLoading.vue'
import PageTitle from '@/components/PageTitle.vue';
import DismissableAlert from '@/components/DismissableAlert.vue';

import Password from 'primevue/password'
import Alert from '@/components/Alert.vue';
import Toast from 'primevue/toast';

import { useToast } from 'vue-toastification';

export default {
    name: "ClientSettingsPageView",
    components: { 
        TemplateView, 
        ContractStatus, 
        PageTitle, 
        FullPageLoading, 
        DismissableAlert, 
        Password, 
        Alert,
        Toast, 
    },
    data(){
        return{
            verification_step: 1,
            verification_steps: [
                { title: 'Show us a government-issued ID', subtitle: "we'll check that the info in your ID  matches the one you provided in your profile."},
                { title: 'Appear on camera', subtitle: "To show us it's really you, take an automatic selfie."},
                { title: "Submit for Identity review", subtitle: "To show us it's really you, take an automatic selfie."},
            ],
            toast: useToast(),
            alerts: [],
            show_alert: false,
            alert_type: '',
            alert_message: '',

            loading: false,
            contracts: '',
            headers: {
                Authorization: `JWT ${localStorage.getItem('life-gaurd')}`
            },
            current_mode: '',
            active_tab: 2,

            user: {
                profile:{
                    phone: '',
                }
            },

            security: {
                password: '',
                new_password: '',
                new_password_confirmation: '',
            },

            password_errors: '',

            settings:{
                bank: {
                    name: '',
                    sort_code: '',
                    account_number: '',
                },
                card: {
                    number: '',
                    date: '',
                    cvv: ''
                },
                notifications:{
                    contracts: true,
                    messages: true,
                    email: true,
                },
                KYC: {
                    NIN_number: '',
                    is_verified: true,
                },
                profile_visibility: '',
            },
            passHint: false,
            rules: [
				{ message:'One lowercase letter required.', regex:/[a-z]+/ },
				{ message:"One uppercase letter required.",  regex:/[A-Z]+/ },
				{ message:"8 characters minimum.", regex:/.{8,}/ },
				{ message:"One number required.", regex:/[0-9]+/ }
			],
        }
    },
    methods:{

        async getUserData(){
            this.loading = true;
            const headers = this.headers;
            try{    
                const response = await axios.get(`${this.api_url}/user`, { headers });
                console.log(response)
                this.user = response.data.user;
                this.user.KYC = response.data.KYC;

                if(this.user.settings){
                    this.settings.profile_visibility = this.user.settings.profile_visibility;
                   /*  if(this.user.settings.bank){
                        this.settings.bank = this.user.settings.bank
                    }
                    if(this.user.settings.card){
                        this.settings.bank = this.user.settings.bank
                    } */
                    if(this.user.settings.notifications){
                        this.settings.notifications = this.user.settings.notifications
                    }
                    if(this.user.settings.KYC){
                        this.settings.KYC = this.user.settings.KYC
                    }
                }
                
                
                this.loading = false;
            }catch(error){
                console.log("error getting user details: ", error);
                this.loading = true;
            }
        },

        async updateUserData(){
            this.loading = true;
            const headers = this.headers;
            try{
                const user = this.user;
                user.settings = this.settings;
                const response = await axios.patch(`${this.api_url}/user/profile`, user, { headers });
                // console.log("set successful: ", response);
                this.toast.success(response.data.message)
                // this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'settings updated successfully!', life: 3000 });
                this.loading = false;
            }catch(error){
                // console.log("error updating user profile: ", error);
                this.toast.error(error.response.data.message)
                this.loading = false;
            }
        },

        async updatePassword(){
            const headers = this.headers;
            this.loading = true;
            console.log("form: ", this.security, " headers ;", headers)
            try{
                const response = await axios.post(`${this.api_url}/user/password/change`, this.security, { headers });
                console.log("password response: ", response);
                this.toast.success(response.data.message)
                this.loading = false;
                this.password_errors = null;
                window.location.reload();
            }catch(error){
                console.log("error changing password: ", error);
                // this.toast.error(error.response.data.message)
                this.password_errors = error.response.data.message;
                this.loading = false;
            }
        },

        proceedToDojahVerification(){
            this.verifying_loading = true;
            setTimeout(()=>{
                window.location.href = "https://identity.dojah.io?widget_id=66b7d38ebe8f00003fdfda63";
            }, 2000)
           
        },
       
    },
    computed:{
        verificationStatus(){
            if(this.user){
                return this.user.KYC.status;
            }
        },

        passwordValidation () {
			let errors = []
			for (let condition of this.rules) {
				if (!condition.regex.test(this.security.new_password)) {
					errors.push(condition.message)
				}
			}
			if (errors.length === 0) {
				return { valid:true, errors }
			} else {
				return { valid:false, errors }
			}
		}
    },

    created(){
        this.getUserData()

    }
}

</script>
<style scoped>
    .section{
        @apply h-full flex flex-col p-2 gap-5
    }

    .tab_button{
        @apply p-3
    }

    .active_tab{
        @apply  border-tz_blue border-b-4
    }

    .theme-toggle{
        @apply  w-24 h-24 p-5 flex justify-center items-center rounded-xl border-4 dark:border-gray-400 dark:bg-tz_light_blue hover:border-blue-500 hover:bg-tz_dark_blue;
    }
    .active_theme{
        @apply border-blue-500 bg-tz_light_blue 
    }
</style>