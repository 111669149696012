<template>
    <div class="h-full w-full flex flex-col justify-center items-center">
        <div class="md:min-w-[400px] flex flex-col gap-3 bg-white dark:bg-gray-700 p-12 rounded-xl max-h-[70vh] overflow-y-auto">
            <h1 class="text-xl font-bold">Submit A Support Ticket</h1>
            <form @submit.prevent="submitForm" class="flex flex-col gap-5 justify-center mt-5">

                <div class="flex flex-row flex-wrap gap-3">

                    <div class="flex flex-col gap-3">
                        <span>firstname</span>
                        <input required type="username" name="username" class="form_input" placeholder="john">
                    </div>
                    <div class="flex flex-col gap-3">
                        <span>lastname</span>
                        <input required type="username" name="username" class="form_input" placeholder="john">
                    </div>
                </div>

                <div class="flex flex-col gap-3">
                    <span>Email</span>
                    <input required type="email" name="email" class="form_input" placeholder="johndoe@mail.com">
                </div>

                <div class="flex flex-col gap-3">
                    <span>Category</span>
                    <select v-model="ticket.category" required class="form_input">
                        <option value="" disabled>select category</option>
                        <option>jobs</option>
                        <option>contracts</option>
                        <option>taskwatch</option>
                        <option>payment & withdrawal</option>
                        <option>messages</option>
                    </select>
                </div>
              
                <div class="flex flex-col gap-3">
                    <span>How can we help?</span>
                    <textarea required type="textarea" name="description" class="form_input rounded-md min-h-52 max-h-52 overflow-auto" placeholder="Tell us about questions and concerns..."></textarea>
                </div>
                
                <div class="flex flex-row gap-3 justify-between">
                    <button type="submit" class="btn !bg-red-500">Cancel</button>
                    <button type="submit" class="btn w-full">Submit</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
/* 

    NAME
    EMAIL
    
    PROBLEM/DESCRIPTION
        JOBS
        CONTRACT
        TASKWATCH
        PAYMENT
    IMAGE
    **STATUS (open, closed)
*/
    export default {
        name: "HelpAndSupportPageView",
        props: {
            email: String,
            firstname: String,
            lastname: String,
            description: String,
        },
        data(){
            return{
                email: '',
                description: '',

                ticket: {
                    category: '',
                }
            }
        },
        methods: {
            async submitForm(){
                try{

                }catch(error){

                }
            }
        }
    }
</script>

<style scoped>

</style>