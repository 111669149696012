<template>
  <div class="camera-component">
    <video ref="video" autoplay playsinline class="video-feed" id="camera_vid"></video>
    <canvas ref="canvas" class="snapshot-canvas" style="display: none;"></canvas>
    <div class="controls absolute bottom-[80px]">
      <button v-if="!show_options" class=" bg-blue-500 !size-[60px] outline outline-offset-4 outline-blue-500 hover:bg-blue-600 hover:outline-blue-600 rounded-full" @click="takeSnapshot"></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CameraComponent",
  emits: ["image-captured"],
  props: {
    use_back_cam: {
      type: Boolean,
      default: false, // Default to front camera
    },
  },
  data() {
    return {
      videoStream: null,
      show_options: false,
    };
  },
  methods: {
    async startCamera() {
      try {
        // Select front or back camera
        const constraints = {
          video: {
            facingMode: this.use_back_cam ? "environment" : "user",
          },
        };

        this.videoStream = await navigator.mediaDevices.getUserMedia(constraints);
        this.$refs.video.srcObject = this.videoStream;
      } catch (error) {
        console.error("Error accessing camera:", error);
        alert("Unable to access the camera. Please check your permissions.");
      }
    },
    takeSnapshot() {
      const cam = document.getElementById("camera_vid");
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");

      // Set canvas size to match video dimensions
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Draw the current video frame onto the canvas
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convert canvas to Base64 and emit it
      const base64Image = canvas.toDataURL("image/jpeg");
      this.$emit("image-captured", base64Image);
      // console.log("image captured: ", base64Image);
      cam.pause();
      this.show_options = true;
    },
    retake() {
      const cam = document.getElementById("camera_vid");
      cam.play();
      this.$emit("video-played");
    },
    stopCamera() {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach((track) => track.stop());
        this.videoStream = null;
      }
    },
  },
  mounted() {
  if (!this.use_back_cam) {
    this.$refs.video.style.setProperty("--flip", "-1");
  }
  this.startCamera();
},
  beforeUnmount() {
    this.stopCamera();
  },
};
</script>


<style scoped>
.camera-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh; /* Full viewport height */
  justify-content: center;
  background-color: black; /* Optional background for better contrast */
}

.video-feed {
  width: 400px;
  height: 100%;
  object-fit: cover;
  transform: scaleX(var(--flip, 1)); /* Use CSS variable to control flipping */
}

</style>
