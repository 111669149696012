<template>
<!-- ACCOUNT VERIFICATION STATUS -->
<div v-if="account_verification_status_modal" class="flex flex-col fixed top-0 left-0 h-screen bg-[rgba(0,0,0,0.8)] dark:bg-[rgba(0,0,0,0.8)] w-full z-40 justify-center items-center">
    <div class="text-center p-12 bg-white dark:bg-gray-800 rounded-lg">
        <i class="bi bi-patch-exclamation-fill text-4xl"></i>
        <h1 class="font-bold text-2xl">Account Verification in Progress</h1>
        <div class="mt-3 flex flex-col gap-3">
            <p class="max-w-[400px]">Your request to verify your account has been sent, 
            if you have passed the screening test you should see a notification badge next to your name.
            </p>
            <small>you will be redirected shortly or <RouterLink to="/" class="text-blue-500 underline">click here</RouterLink></small>
            <!-- <button class="btn" @click="account_verification_status_modal = !account_verification_status_modal">Continue</button> -->
        </div>
    </div>
</div>


<!-- PASSWORD 2FA FOR ACCOUNT DELETE -->
<div v-if="requested_account_delete" class="flex flex-col fixed top-0 left-0 h-screen bg-[rgba(0,0,0,0.8)] dark:bg-[rgba(0,0,0,0.8)] w-full z-40 justify-center items-center">
    <form @submit.prevent="checkPassword" class="flex flex-col bg-white dark:bg-black rounded-lg p-12 gap-3">
        <h1 class="text-xl ">Please input your password to continue</h1>
        <input name="password" id="password" type="password" class="form_input" v-model="fwp_password">
        <div class="flex flex-row gap-3">
            <button type="button" @click="requested_account_delete = !requested_account_delete" class="bg-gray-100 dark:bg-gray-600 p-3 rounded-md font-bold">cancel</button>
            <button type="submit" class="btn w-full" :disabled="fwp_password == ''">continue</button>
        </div>
    </form>
</div>

<!-- PASSWORD 2FA FOR FUNDS WITHDRAWAL -->
<div v-if="requested_withdrawal" class="flex flex-col fixed top-0 left-0 h-screen bg-[rgba(0,0,0,0.8)] dark:bg-[rgba(0,0,0,0.8)] w-full z-40 justify-center items-center">
    <form @submit.prevent="checkPassword" class="flex flex-col bg-white dark:bg-black rounded-lg p-12 gap-3">
        <h1 class="text-xl ">Please input your password to continue</h1>
        <input name="password" id="password" type="password" class="form_input" v-model="fwp_password">
        <div class="flex flex-row gap-3">
            <button type="button" @click="requested_withdrawal = !requested_withdrawal" class="bg-gray-100 dark:bg-gray-600 p-3 rounded-md font-bold">cancel</button>
            <button type="submit" class="btn w-full" :disabled="fwp_password == ''">continue</button>
        </div>
    </form>
</div>


<!-- CARD UPDATE MODAL -->
<transition name="formSlide">
    <div v-if="edit_card_details" class="flex flex-col fixed top-0 left-0 h-screen bg-[rgba(0,0,0,0.8)] dark:bg-[rgba(0,0,0,0.8)] w-full z-40 justify-center items-center p-3">
        <div class="flex flex-col w-fit">
            <div class="card flex flex-col rounded-t-xl w-auto relative bg-blue-500 p-6 text-white">
                <button @click="edit_card_details = !edit_card_details" class="absolute right-4 top-4">
                    <i class="bi bi-x-lg"></i>
                </button>
                <div class="text-xl mt-6">{{ user.settings.bank.account_number }}</div>
                <div class="mt-5">
                    <span class=" opacity-70 uppercase text-[12px]">account name</span>
                    <div>
                        <span v-if="user.settings.bank.account_name">{{ user.settings.bank.account_name }}</span>
                        <span v-else>{{ user.firstname }} {{ user.lastname }}</span>
                    </div>
                </div>
            </div>
            <div class="flex flex-col bg-white dark:bg-gray-800">
                <form @submit.prevent="updateUserAccount" class="p-5 flex flex-col gap-5">
                    <div class="flex flex-col">
                        <span class=" text-gray-500 text-sm">ACCOUNT NUMBER</span>
                        <div class="border-b border-black">
                            <input class="border-b border-none outline-none w-full bg-transparent" type="number" name="account-number" v-model="user.settings.bank.account_number">
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <span class=" text-gray-500 text-sm">ACCOUNT NAME</span>
                        <div class="border-b border-black">
                            <input class="border-b border-none !outline-none w-full bg-transparent" type="text" name="account-name" v-model="user.settings.bank.account_name">
                        </div>
                    </div>
                    <div class="flex flex-row flex-wrap gap-3 justify-between items-center">
                        <div class="flex flex-col">
                            <span class="text-gray-500 text-sm">BANK NAME</span>
                            <div class="border-b border-black  bg-transparent">
                                <select class="p-3 border-none"v-model="bank.name" @change="setBankCode">
                                    <option value="" disabled>select bank</option>
                                    <option v-for="bank in banks" :value="bank.name">{{ bank.name }}</option>
                                </select>
                            </div>
                        </div>
                        <!-- <div>
                            <span class="text-gray-500 text-sm">
                                BANK CODE
                            </span>
                            <div class="py-2 text-gray-500 border-b border-black">{{ bank.code }}</div>
                        </div> -->
                    </div>
                </form>
                <button type="button" @click="updateUserAccount" class=" bg-tz_blue text-white p-5 uppercase font-bold text-sm">update card</button>
            </div>
        </div>
    </div>
</transition>


 <!-- REQUEST WITHDRAW MODAL -->
<transition name="formSlide">
    <form v-if="withdraw_funds_modal" @submit.prevent="instantWithdrawFunds" class="flex flex-col fixed top-0 left-0 h-screen bg-[rgba(0,0,0,0.8)] dark:bg-[rgba(0,0,0,0.8)] w-full z-40 justify-center items-center">
        <div class="flex flex-col p-12 rounded-lg bg-white dark:bg-gray-800 w-fit md:w-[80vw] max-w-[1050px]  max-h-[90vh] relative overflow-auto">
            <button @click="withdraw_funds_modal = !withdraw_funds_modal" type="button" class="absolute top-4 text-xl right-4">
                <i class="bi bi-x-lg"></i>
            </button>

            <div class="flex flex-col md:flex-row gap-3 mt-6">
                <div class=" flex flex-col w-full md:w-[50%]">
                    <h1 class="font-bold">Withdrawal Method</h1>
                  <div class="flex flex-col w-full p-5">
                    <div class="flex flex-row gap-3 mb-6 text-[12px]">
                        <label for="account_1" class="flex justify-center items-center gap-3">
                            <span>Account 1</span>
                            <input type="radio" id="account_1" value="account_1" name="accounts" checked>
                        </label>
                        <label for="account_2" class="flex justify-center items-center gap-3">
                            <span class="text-gray-300">Account 2</span>
                            <input type="radio" id="account_2" value="account_2" name="accounts" disabled>
                        </label>
                    </div>
                    <div class="flex flex-col">
                        <form class=" flex flex-col gap-5">
                            <div class="flex flex-col">
                                <span class=" text-gray-500 text-sm">ACCOUNT NUMBER</span>
                                <div class="border-b border-gray-400 dark:border-gray-600 py-3 text-gray-300 uppercase">
                                    {{ user.settings.bank.account_number }}
                                </div>
                            </div>
                            <div class="flex flex-col">
                                <span class=" text-gray-500 text-sm">ACCOUNT NAME</span>
                                <div class="border-b border-gray-400 dark:border-gray-600 py-3 text-gray-300 uppercase">
                                    {{ user.firstname }}
                                    {{ user.lastname }}
                                    <!-- {{ user.settings.bank.account_number }} -->
                                </div>
                            </div>
                            <div class="flex flex-row gap-3 justify-between items-center w-full">
                                <div class="flex flex-col w-full">
                                    <span class="text-gray-500 text-sm">BANK NAME</span>
                                    <div class="border-b border-gray-400 dark:border-gray-600 py-3 text-gray-300">
                                        {{ user.settings.bank.name }}
                                    </div>
                                </div>
                                <!-- <div>
                                    <span class="text-gray-500 text-sm">
                                        BANK CODE
                                    </span>
                                    <div class="border-b border-gray-400 dark:border-gray-600 py-3 text-gray-300">
                                        {{  user.settings.bank.sort_code }}
                                    </div>
                                </div> -->
                            </div>
                        </form>
                    </div>
                  </div>

                  <div class="flex flex-col w-full mt-6">
                    <h1  class="font-bold">Withdrawal Details</h1>
                    <div class="flex flex-col gap-8 p-3">
                        <span v-if="withdrawal_amount_error" class="bg-red-100 text-red-500 p-3 rounded-lg">
                            <i class="bi bi-exclamation-circle-fill"></i>
                            insufficient funds in account
                        </span>
                        <label for="amount" class="flex flex-row gap-3 justify-between items-center">
                            <span class="text-sm">Widthdrawal Amount</span>
                            <div class="flex flex-row items-center justify-center gap-3">
                                <span class="text-gray-500">NGN</span>
                                <input type="number" name="amount" id="amount" :max="account_balance" @keyup="checkBalance" class="form_input w-[100px] overflow-auto !border-red-500 !outline-none" placeholder="100,000" v-model="withdrawal_amount">
                            </div>
                        </label>
                        
                        <!-- <div class="flex flex-col gap-3">
                            <label for="password">
                                <span class="text-sm">Account Password</span>
                                <input type="password" placeholder="Acount password" name="password" id="password" class="form_input w-full">
                            </label>
                        </div> -->
                    </div>
                  </div>
                </div>
                <div class=" flex flex-col w-full md:w-[50%]">
                    <div class="flex flex-col">
                        <h1 class="font-bold">Withdrawal Summary</h1>
                        <div class="flex flex-col gap-3 mt-3 p-3">
                            <div class="flex flex-row justify-between w-full">
                                <span>Current Balance</span>
                                <span>NGN {{ account_balance.toLocaleString() }}</span>
                            </div>

                            <div class="flex flex-row justify-between w-full">
                                <span>Withdrawal Amount</span>
                                <span class="text-tz_blue text-xl font-bold">NGN 
                                    <span v-if="!withdrawal_amount_error">{{ withdrawal_amount }}</span>
                                    <span v-else>0.00</span>
                                </span>
                            </div>

                            <div class="flex flex-row justify-between w-full">
                                <span>Balance After Withdrawal</span>
                                <span v-if="!withdrawal_amount_error">NGN {{ (account_balance - withdrawal_amount).toLocaleString() }}</span>
                                <span v-else>0.00</span>
                            </div>
                        </div>
                        <img class="hidden md:flex" src="../../assets/images/withdrawal-image.png">
                    </div>
                </div>
            </div>
            <button type="submit" class="btn uppercase text-sm mt-6" :disabled="withdrawal_amount_error">withdraw funds</button>
        </div>
    </form>
</transition>






    <PageTitle>Settings</PageTitle>
    <div class="h-full flex flex-col relative">
        <FullPageLoading v-if="loading"/>
       
        <div class=" bg-white tab flex ps-2 flex-row flex-wrap gap-2 dark:bg-[#1F2A36] border-b dark:border-gray-500 sticky top-0">
            <!-- <button @click="active_tab = 1" :class="active_tab == 1 ? 'active_tab':''" class="tab_button">Appearance & theme</button> -->
            <RouterLink :to="{path: '/in/settings', query: { tab: `profile` } }">
                <button :class="active_tab == 2 ? 'active_tab':''" class="tab_button">Profile & Account</button>
            </RouterLink>

            <RouterLink :to="{path: '/in/settings', query: { tab: `notifications` } }">
                <button :class="active_tab == 3 ? 'active_tab':''" class="tab_button">Notifications</button>
            </RouterLink>

            <RouterLink :to="{path: '/in/settings', query: { tab: `payout` } }">
                <button :class="active_tab == 4 ? 'active_tab':''" class="tab_button">Funds Withdrawal</button>
            </RouterLink>

            <RouterLink :to="{path: '/in/settings', query: { tab: `verification` } }">
                <button :class="active_tab == 5 ? 'active_tab':''" class="tab_button">ID & Verification</button>
            </RouterLink>
        </div>


        <div class=" flex flex-col p-3 gap-5">
            <div v-if="active_tab == 1" class="section">
                <h1 class="font-bold mb-3 text-lg">Select Preffered theme</h1>
                
                <div class="  flex flex-row gap-5">
                    <button @click="system_mode" :class="!current_mode ? 'active_theme':''" class="theme-toggle">System</button>
                    <button @click="light_mode()" :class="current_mode == 'light' ? 'active_theme':''" class="theme-toggle">
                        <i class="pi pi-sun"></i>
                    </button>
                    <button @click="dark_mode()" :class="current_mode == 'dark' ? 'active_theme':''" class="theme-toggle">
                        <i class="pi pi-moon"></i>
                    </button>
                </div>
            </div>

            <!-- PROFILE ACCOUNT -->
            <div v-if="active_tab == 2" class="section">
                <form @submit.prevent="updateUserData" class=" mt-3">
                    <h1 class="font-bold mb-3 text-lg">General Information</h1>
                    <div class="flex flex-row gap-3 mb-3">
                        <div class="w-3/6 flex flex-col">
                            <label for="firstname">firstname</label>
                            <!-- <input class="form_input" type="name" name="firstname" id="firstname" :value="user.firstname" disabled>? -->
                            <div class="form_input">{{ user.firstname }}</div>
                        </div>

                        <div class="w-3/6 flex flex-col">
                            <label for="firstname">lastname</label>
                            <!-- <input class="form_input" type="name" name="lastname" id="lastname" :value="user.lastname" disabled> -->
                            <div class="form_input">{{ user.lastname }}</div>
                        </div>
                    </div>
                    <div class="flex flex-col w-full">
                        <label for="email">Email</label>
                        <input class="form_input" type="email" name="email" id="email" :value="user.email">
                    </div>
                    <div class="flex flex-col w-full mt-3">
                        <label for="email">Phone Number</label>
                        <input v-if="user.profile" class="form_input" type="phone" name="phone" id="phone" v-model="user.profile.phone">
                    </div>
                    <button class="btn mt-3" type="submit">Update</button>
                </form>

                <div @submit.prevent="updateUserData" class="mt-3">
                    <h1 class="font-bold mb-3 text-lg">Profile visibility</h1>
                    <!-- settings: {{ user.settings }} -->
                    <div class="flex items-center p-4 mb-4 text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 dark:border-blue-800" role="alert">
                        <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                        </svg>
                        <span class="sr-only">Info</span>
                        <div>In case of account inactivity your profile is automaitcally set private</div>
                    </div>
                    <div class="flex items-center mb-4">
                        <input id="default-radio-1" type="radio" value="public" name="profile-visibility" @change="updateUserData" v-model="settings.profile_visibility"  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="default-radio-1" class="ms-2 text-lg  font-medium text-gray-900 dark:text-gray-300">Public</label>
                    </div>
                    <div class="flex items-center">
                        <input id="default-radio-2" type="radio" value="private" name="profile-visibility" @change="updateUserData" v-model="settings.profile_visibility"  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="default-radio-2" class="ms-2 text-lg font-medium text-gray-900 dark:text-gray-300">Private</label>
                    </div>
                </div>


                <form class="" @submit.prevent="updatePassword">
                    <h1 class="font-bold mb-3 text-lg mt-3">Password & Security</h1>
                    <div class="flex flex-col max-w-sm">
                        <label for="email">Current Password</label>
                        <input class="form_input" type="password" name="password" id="password" v-model="security.password" required>
                    </div>

                    <div class=" relative flex flex-col gap-3 justify-center max-w-sm mt-3">

                        <div v-if='passHint && passwordValidation.errors.length > 0' class='hints relative pt-6'>
                            <!-- <button @click="{passHint = !passHint; passwordValidation.errors.length = 0}" type="button" class=" absolute top-2 right-2"><i class="bi bi-x-lg"></i></button> -->
                            <small class="text-orange-600" v-for='error in passwordValidation.errors'>
                                <i class="bi bi-exclamation-circle"></i> {{ error }}<br/>
                            </small>
                        </div>

                        <div class="tz_form_control">
                            <label for="password">New password</label>
                            <input @click="passHint = true" class="form_input" type="password" name="password" id="password" placeholder="a very strong password" v-model="security.new_password" required>
                        </div>
                        <div class="tz_form_control">
                            <label for="password">New password confirmation</label>
                            <input class="form_input" type="password" name="password_confirmation" id="password_confirmation" placeholder="Confirm your password" v-model="security.new_password_confirmation" required>
                        </div>
                    </div>


                    <div class="text-red-500 py-3" type="danger" v-if="password_errors">
                        <i class="bi bi-exclamation-triangle-fill mr-3"></i>
                        <span>{{  password_errors }}</span>
                    </div>
                    <button type="button" @click="updatePassword" class="btn mt-3" :disabled="!passwordValidation.valid">Change Account Password</button>
                </form>




                <div class=" mt-3 pb-3">
                    <h1 class="font-bold mb-3 text-lg mt-3">Account & Profile</h1>
                    <p class=" text-red-700 bg-red-500 bg-opacity-10 rounded-lg p-3">
                    <strong>Warning</strong><br/>
                    <span>Deleting your account cannot be undone. Any personally identifiable information will be deleted and any in-progress data will be lost.</span>
                    </p>
                    <button disabled type="button" @click="requested_account_delete = !requested_account_delete" class=" bg-red-500 text-white hover:bg-red-700 rounded-md p-3 mt-3">Delete Account</button>
                </div>
            </div>

            <!-- NOTIFICATIONS -->
            <form @submit.prevent="updateUserData" v-if="active_tab == 3" class="section mb-3">
                <h1 class="font-bold mb-3 text-lg">Notification Settings</h1>
                <!-- <div class=" flex flex-row items-center justify-between justify-center">
                    <div class="flex flex-col">
                        <span class="font-bold">Contract Notifications</span>
                        <span class="text-sm w-72 text-gray-400">Allow ApexTeks send you email notifications for contract actions and updates.</span>
                    </div>
                    
                    <label class="inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" class="sr-only peer" @change="updateUserData" v-model="settings.notifications.contracts">
                        <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                </div> -->
                <div class="text-gray-500">
                    All email notifications are sent to: {{ user.email }}
                </div>
                <div class=" flex flex-row items-center justify-between">
                    
                    <div class="flex flex-col">
                        <span class="font-bold">Message Notifications</span>
                        <span class="text-sm w-72 text-gray-400">Allow ApexTeks send you email notifications for messages.</span>
                    </div>
                    
                    <label class="inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="true" class="sr-only peer" @change="updateUserData" v-model="settings.notifications.messages">
                        <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                </div>

                <div class=" flex flex-row items-center justify-between justify-center">
                    <div class="flex flex-col">
                        <span class="font-bold">Email Notifications</span>
                        <span class="text-sm w-72 text-gray-400">Allow ApexTeks send you email notifications for updates and activities related to your account.</span>
                    </div>
                    
                    <label class="inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" class="sr-only peer cursor-not-allowed" @change="updateUserData" v-model="settings.notifications.emails" disabled>
                        <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    </label>
                </div>
            </form>

            <!-- FUNDS WITHDRAWAL -->
            <form @submit.prevent="updateUserData" v-if="active_tab == 4" class="section mb-3">
                <div class="flex flex-col gap-12">
                    <div class="flex flex-col gap-3">
                        <p class="font-bold">Current Balance</p>
                        <Skeleton v-if="loading_wallet" height="50px" width="220px"/>
                        <div class="text-2xl font-bold flex flex-row gap-1 items-center">
                            <div class="p-3 bg-gray-800 rounded-full flex justify-center items-center w-[50px] h-[50px] text-white">₦</div>
                            <span class="inline-block text-4xl"> {{ account_balance.toLocaleString() }}.00</span>
                        </div>
                    </div>

                    <!-- card details -->
                    <div class="flex flex-col gap-12">
                        <div class="flex flex-col gap-4">
                            <span class="font-bold">Account Details</span>
                            <div class="flex flex-row flex-wrap gap-12 overflow-x-auto">

                                <!-- LOADER -->
                                 <!-- <Skeleton height="215px" width="360px" v-for="card in 2"/> -->
                                 
                                <!-- CARD 1 -->
                                <div v-if="user.settings.bank" @click="edit_card_details = !edit_card_details" class="card h-[215px] w-[360px] rounded-lg bg-blue-600 flex flex-col p-5 justify-between cursor-pointer uppercase text-white">
                                    <div class="text-sm opacity-60">{{ user.settings.bank.name }}</div>
                                    <div class="text-3xl">{{ user.settings.bank.account_number }}</div>
                                    <div class="flex flex-col">
                                        <span class=" text-sm opacity-60">ACCOUNT NAME</span>
                                        <span class=" capitalize text-xl">{{ user.firstname }} {{ user.lastname }}</span>
                                    </div>
                                </div>
                                <!-- <div class="card h-[215px] w-[360px] rounded-lg bg-green-600"></div> -->
                            </div>
                        </div>
                        <div class="flex flex-col gap-4 bg-gray-50 dark:bg-gray-700 p-6 w-full rounded-lg">
                            <span class="dark:bg-gray-800 bg-slate-300 text-center font-bold rounded-lg p-3">Notice on Payment</span>
                            
                            <ol start="1" class=" list-decimal p-3">
                                <li>All available funds will be sent to provided bank card details only when withdrawal has been requested</li>
                                <li>Funds Withdrawal is only available on weekends</li>
                            </ol>
                        </div>
                    </div>

                    <!-- Actions... -->
                    <div class="flex flex-col gap-6">
                        <div class="flex flex-col gap-3 bg-gray-50 dark:bg-gray-700 rounded-lg p-6">
                            <span class="font-bold">Actions</span>
                            <div class="flex flex-row flex-wrap gap-10 items-start md:justify-start justify-center px-12">
                                <!-- <button @click="withdraw_funds_modal = !withdraw_funds_modal" type="button" class="action-btn"> -->
                                <button @click="requested_withdrawal = !requested_withdrawal" type="button" class="action-btn">
                                    <i class="bi bi-cash-stack p-3 action-icon"></i>
                                    <span>Request Withdrawal</span>
                                </button>

                                <button type="button" @click="edit_card_details = !edit_card_details" class="action-btn">
                                    <i class="bi bi-arrow-repeat p-3 action-icon"></i>
                                    <span>Update Details</span>
                                </button>

                                <button type="button" class="action-btn">
                                    <i class="bi bi-plus-lg p-3 action-icon"></i>
                                    <span>Add New Account</span>
                                </button>

                            </div>
                        </div>
                        <div class="flex flex-col gap-3 bg-gray-50 dark:bg-gray-700 rounded-lg p-6">
                            <span class="font-bold">Recent Transactions</span>
                            
                            <div class="overflow-x-auto max-w-screen-lg flex flex-col" v-if="transactions.length > 0">
                                <!-- LOADER -->
                                <!-- <Skeleton height="20px" width="500px"/> -->
                                <div v-for="(transaction, index) in transactions" class="flex flex-row justify-between items-center hover:bg-black hover:bg-opacity-10 p-3" :key="index">
                                    <span>{{ readableDateFormat(transaction.date) }} </span>
                                    <span :class="transaction.status == 'successful' ? 'text-green-500':'text-red-500'">{{ transaction.status }}</span>
                                    <span>NGN {{ transaction.amount.toLocaleString() }}</span>
                                </div>
                            </div>
                            <div v-else class=" h-28 w-full flex justify-center items-center text-center"> No recent transactions </div>
                        </div>
                    </div>
                </div>
            </form>

            

            <!-- ID & VERIFICATION -->
            <!-- <form @submit.prevent="verifyUserNow"  v-if="active_tab == 5" class="section mb-3"> -->
                <div  v-if="active_tab == 5" class="section mb-3">
                
                <div class="mt-3">
                <!-- {{ verificationStatus }} -->
                    <h1 class="font-bold mb-3 text-xl capitalize">Identity verification - {{  verificationStatus }}</h1>
                    <div class="flex border dark:border-gray-600 rounded-xl p-5 flex-col">
                        <h3 class=" font-bold text-lg">Start your verification</h3>
                        <div class="flex flex-row">
                            <div>
                                <div></div>
                            </div>
                            <div class="relative flex flex-col gap-8 mt-8 w-full">
                                <div class="flex flex-row gap-3" v-for="step in verification_steps">
                                    <div :class="verificationStatus == 'verified' ? 'bg-green-500':'bg-blue-500'" class=" !size-8 min-w-[32px] rounded-full text-white flex justify-center items-center">
                                        <i v-if="verificationStatus == 'pending'" class="bi bi-exclamation"></i>
                                        <i v-if="verificationStatus == 'in progress'" class="bi bi-arrow-clockwise"></i>
                                        <i v-if="verificationStatus == 'verified'" class="bi bi-check"></i>
                                    </div>
                                    
                                    <div class="flex flex-col">
                                        <span>{{ step.title }}</span>
                                        <small>{{ step.subtitle }}</small>
                                    </div>
                                </div>
                                <div v-if="verificationStatus == 'pending'" class=" w-full flex flex-row justify-end items-center">
                                    <RouterLink :to="`/KYC/${user._id}/verification`">
                                        <button class="btn">Continue</button>
                                    </RouterLink>
                                </div>
                            </div>
                        </div>
                    </div>

                                  
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import JobDetailCard from '@/components/JobDetailCard.vue';
import TemplateView from '../TemplateView.vue';
import ContractStatus from '@/components/ContractStatus.vue';
import FullPageLoading from '@/components/FullPageLoading.vue'
import PageTitle from '@/components/PageTitle.vue';
import DismissableAlert from '@/components/DismissableAlert.vue';

import Password from 'primevue/password'
import Alert from '@/components/Alert.vue';

import AutoComplete from 'primevue/autocomplete';
import Dropdown from 'primevue/dropdown';

import { useToast } from 'vue-toastification'

// import ngBanks from 'ng-banks';
import Skeleton from 'primevue/skeleton';
import { WebCamUI } from 'vue-camera-lib';

import { format } from 'date-fns';
import { RouterLink } from 'vue-router';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import CameraComponent from '@/components/CameraComponent.vue';

import Qrcode, { QrcodeCanvas, QrcodeSvg } from 'qrcode.vue';

// import Camera from 'simple-vue-camera';

export default {
    name: "SettingsPageView",
    components: { 
        TemplateView, 
        ContractStatus, 
        PageTitle, 
        FullPageLoading, 
        DismissableAlert, 
        Password, 
        Alert, 
        AutoComplete,
        Dropdown,
        Skeleton,
        WebCamUI,
        SpinnerComponent,
        CameraComponent,
        Qrcode,
        QrcodeCanvas,
        QrcodeSvg,
        // Camera,
    },
    data(){
        return{
            // ngBanks,
            KYC: {
                NIN: '',
                documents: {
                    selfie_image: '',
                    NIN_slip: '',
                }
            },
            verification_step: 1,
            verification_steps: [
                { title: 'Show us a government-issued ID', subtitle: "we'll check that the info in your ID  matches the one you provided in your profile."},
                { title: 'Appear on camera', subtitle: "To show us it's really you, take an automatic selfie."},
                { title: "Submit for Identity review", subtitle: "To show us it's really you, take an automatic selfie."},
            ],
            capturedImages: [],
            capturedImage: null, // Holds the Base64 image strin
            format,
            toast: useToast(),
            alerts: [],
            show_alert: false,
            alert_type: '',
            alert_message: '',

            loading: false,
            contracts: '',
            headers: {
                Authorization: `JWT ${localStorage.getItem('life-gaurd')}`
            },
            current_mode: '',
            active_tab: 2,

            user: {
                profile:{
                    phone: '',
                },
                settings: {
                    bank: '',
                    name: '',
                }
            },

            security: {
                password: '',
                new_password: '',
                new_password_confirmation: '',
            },

            password_errors: '',

            bank: {
                name: '',
                code: '',
            },

            settings:{
                bank: {
                    name: '',
                    code: '',
                    account_number: '',
                    account_name: '',
                },
                card: {
                    number: '',
                    date: '',
                    cvv: ''
                },
                notifications:{
                    contracts: true,
                    messages: true,
                    email: true,
                },
                KYC: {
                    NIN_number: '',
                    is_verified: true,
                },
                profile_visibility: '',
            },

            banks: [],

            // user wallet and balance...
            account_balance: 0,
            fwp_password: '',
            requested_withdrawal: false,
            password_approved: false,
            withdrawal_errors: '',
            withdrawal_amount: 0,

            edit_card_details: false,
            withdraw_funds_modal: false,

            loading_wallet: false,
            withdrawal_amount_error: false,

            imageUrl: null,
            errorMessage: null,
            base64Image: null,
            maxFileSize: 2 * 1024 * 1024, // 2MB in bytes

            verifying_loading: false,
            requested_account_delete: false,
            verify_modal: false,

            transactions: [],

            account_verification_status_modal: false,

            passHint: false,
            rules: [
				{ message:'One lowercase letter required.', regex:/[a-z]+/ },
				{ message:"One uppercase letter required.",  regex:/[A-Z]+/ },
				{ message:"8 characters minimum.", regex:/.{8,}/ },
				{ message:"One number required.", regex:/[0-9]+/ }
			],
        }
    },
    methods:{
        validateNIN(){
            this.KYC.NIN = this.KYC.NIN.replace(/[^0-9]/g, "");
        },


        readableDateFormat(date){
            return this.format( new Date(date), "EEEE do MMMM yyyy")
        },

        // http://localhost:8080/in/settings?tab=verification&verification_status=in_progress
        checkAccountVerifyStatus(){
            const checker = this.$route.query.verification_status;
            console.log(" route: ", this.$route.query);
            if(checker){
                // alert("you requested account verification")
                this.requestAccountVerification();
                this.account_verification_status_modal = true;
            }
        },

        proceedToDojahVerification(){
            this.verifying_loading = true;
            setTimeout(()=>{
                window.location.href = "https://identity.dojah.io?widget_id=66b7d38ebe8f00003fdfda63";
            }, 2000)
           
        },

        onFileChange(event) {
            const file = event.target.files[0];
            this.errorMessage = null;
            this.base64Image = null;

            if (file) {
                if (!file.type.startsWith("image/")) {
                this.errorMessage = "Please select a valid image file.";
                } else if (file.size > this.maxFileSize) {
                this.errorMessage = "Image size should not exceed 2MB.";
                } else {
                this.imageUrl = URL.createObjectURL(file);
                this.convertToBase64(file);
                }
            }
        },

        convertToBase64(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                // Remove the prefix from the Base64 string
                const base64String = e.target.result;
                this.base64Image = base64String.replace(/^data:image\/[a-zA-Z]+;base64,/, '');
            };
            reader.readAsDataURL(file);
        },

        theme(){
            const mode = localStorage.getItem('theme');
            this.current_mode = mode;
            if(!mode){
                this.current_mode = null
            }
            console.log("current theme: ", this.current_mode)
        },

        system_mode(){
            localStorage.removeItem('theme');
            window.location.reload()
        },

        light_mode(){
            localStorage.setItem('theme', 'light');
            document.documentElement.classList.remove("dark");
        },

        dark_mode(){
            localStorage.setItem('theme', 'dark');
            document.documentElement.classList.add("dark");
        },

        setBankCode() {
            const bank = this.banks.find(b => b.name === this.bank.name);
            this.bank.code = bank ? bank.code : '';
            this.settings.bank.name = this.bank.name;
            this.settings.bank.sort_code = this.bank.code;
        },

        checkBalance(){
            if(this.withdrawal_amount > this.account_balance){
                // this.withdrawal_amount = 0
                this.withdrawal_amount_error = true;
            } else {
                this.withdrawal_amount_error = false;
            }
        },


        async requestAccountVerification(){
            try{
                const response = await axios.patch(`/user/account/${this.user._id}/verification_request`);
                console.log("res from veirifcation request: ", response);
                setTimeout(()=>{
                    window.location.href = "/"
                }, 3000);
                
            }catch(error){
                console.error(error);
                this.toast.error(error.response.data.message);
            }
        },

        async getBanks(){
            try{
                this.loading = true;
                // const response = await axios.get(`${this.api_url}/contracts/banks/list`);
                const response = await axios.get(`${this.api_url}/contracts/banks/list`);
                console.log("bank lists: ", response);
                // this.banks = response.data.banks;
                this.banks = response.data.result.detail.data;
                this.loading = false;
            }catch(error){
                this.loading = false;
                this.toast.error(error.response.data.message);
            }
        },

        async getUserData(){
            this.loading = true;   
            const headers = this.headers;
            try{    
                const response = await axios.get(`${this.api_url}/user`, { headers });
                console.log("user data: ", response)
                this.user = response.data.user;
                this.user.KYC = response.data.KYC;

                if(this.user.settings){
                    this.settings.profile_visibility = this.user.settings.profile_visibility;
                    // this.user.settings.
                    if(this.user.settings.bank){
                        this.settings.bank = this.user.settings.bank
                        this.bank.name = this.settings.bank.name;
                        this.bank.code = this.settings.bank.sort_code;
                    }
                    if(this.user.settings.card){
                        this.settings.bank = this.user.settings.bank
                    }
                    if(this.user.settings.notifications){
                        this.settings.notifications = this.user.settings.notifications
                    }
                };

                
                this.getUserWallet();
                this.checkAccountVerifyStatus();
                
                
                this.loading = false;
            }catch(error){
                this.toast.error(error.response.data.message);
                this.loading = true;
            }
        },


        // GET USER WALLET FOR BALANCE DISPLAY...
        async getUserWallet(){
            try{
                this.loading_wallet = true;
                const headers = this.headers;
                const response  = await axios.get(`/wallets/info`, { headers });
                console.log("user wallet: ", response.data.wallet);
                this.account_balance = response.data.wallet.balance;
                // get only first five wallet transactions...
                this.transactions = response.data.wallet.transactions.splice(0, 5);
                this.loading_wallet = false;
            }catch(error){
                this.loading_wallet = false;
                console.error("erro getting user wallet: ", error);
            }
        },


        async updateUserAccount(){
            this.loading = true;
            const headers = this.headers;
            try{
                const form = this.settings.bank;
                const response = await axios.patch(`${this.api_url}/user/bank/update`, form, { headers });
                this.toast.success(response.data.message);
                console.log("res from bank update: ", response);
                this.loading = false;
                this.edit_card_details = false;
            }catch(error){
                this.toast.error(error.response.data.message);
                console.log("erro updating user bank: ", error);
                this.loading = false;
            }
        },

        async updateUserData(){
            this.loading = true;
            const headers = this.headers;
            try{
                const user = this.user;
                user.settings = this.settings;
                const response = await axios.patch(`${this.api_url}/user/profile`, user, { headers });
                // console.log("set successful: ", response);
                this.toast.success(response.data.message);
                this.loading = false;
            }catch(error){
                this.toast.error(error.response.data.message);
            }
        },

        async updatePassword(){
            
            try{
                if(this.security.password == '' || this.security.new_password == '' || this.security.new_password_confirmation == ''){
                    this.toast.error("invalid data provided!");
                } else {
                const headers = this.headers;
                this.loading = true;
                const response = await axios.post(`${this.api_url}/user/password/change`, this.security, { headers });
                console.log("password response: ", response);
                alert("Password Updated Successfully!")
                this.loading = false;
                this.toast.success(response.data.message);
                }
            }catch(error){
                // console.log("error changing password: ", error);
                this.toast.error(error.response.data.message);
                this.password_errors = error.response.data.message;
                this.loading = false;
            }
        },

        async checkPassword(){
            try{
                const headers = this.headers;
                const response = await axios.post(`${this.api_url}/password/check`, {password: this.fwp_password}, { headers });
                console.log("from pass checker: ", response);
                this.requested_withdrawal = false,
                this.withdraw_funds_modal = response.data.authenticated;
                this.fwp_password = ''
                // this.toast.info(response.data.message);
            }catch(error){
                // console.log("problem with pass checker: ", error)
                this.requested_withdrawal = false,
                this.fwp_password = ''
                this.toast.error(error.response.data.message);
            }
        },

        async instantWithdrawFunds(){
            try{
                const headers = this.headers;
                const response = await axios.post(`${this.api_url}/funds/withdraw`, { amount: this.withdrawal_amount }, { headers });
                this.toast.success(response.data.message);
                this.withdraw_funds_modal = !this.withdraw_funds_modal;
            }catch(error){
                this.toast.error(error.response.data.message);
            }
        },
       
    },
    computed: {
        verificationStatus(){
            if(this.user){
                return this.user.KYC.status;
            }
        },

        passwordValidation () {
			let errors = []
			for (let condition of this.rules) {
				if (!condition.regex.test(this.security.new_password)) {
					errors.push(condition.message)
				}
			}
			if (errors.length === 0) {
				return { valid:true, errors }
			} else {
				return { valid:false, errors }
			}
		}
    },

    updated(){
        
        if(this.$route.query.tab == 'profile'){
            this.active_tab = 2;
        } else if (this.$route.query.tab == 'notifications'){
            this.active_tab = 3
        } else if(this.$route.query.tab == 'payout'){
            this.active_tab = 4;
        } else if(this.$route.query.tab == 'verification'){
            this.active_tab = 5;
        }
    },

    created(){
        this.theme();
        this.getUserData();

       
            
        // this.getBanks();
    }
}
</script>
<style scoped>
    .section{
        @apply h-full flex flex-col p-2 gap-5
    }

    .tab_button{
        @apply p-3
    }

    .active_tab{
        @apply  border-tz_blue border-b-4
    }

    .theme-toggle{
        @apply  w-24 h-24 p-5 flex justify-center items-center rounded-xl border-4 dark:border-gray-400 dark:bg-tz_light_blue hover:bg-tz_blue hover:text-white ;
    }
    .active_theme{
        @apply border-blue-500 bg-tz_light_blue 
    }


    .card{
        background: url('../../assets/images/card-blue.png');
        background-size: cover;
        background-position: center;
    }


    .formSlide-enter-active, .formSlide-leave-active {
        transition: all 0.5s;
        opacity: 1;
    }
    .formSlide-enter-from, .formSlide-leave-to {
        opacity: 0;
        padding-bottom: 50px;
    }


    .action-icon{
        @apply justify-center items-center flex rounded-full bg-blue-50 dark:bg-blue-400 text-blue-500 h-[50px] w-[50px]
    }

    .action-btn{
        @apply flex flex-col gap-3 justify-center items-center text-center font-bold
    }


    .image-upload-box {
  width: 200px;
  height: 200px;
  border: 2px dashed #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
}

.select-image-text {
  font-size: 16px;
  color: #777;
}

.preview-image {
  max-width: 100%;
  max-height: 100%;
}
</style>