<template>
    <!-- <div> -->
   <!-- MINI FOOTER STARTS HERE -->
   <!-- <div class=" mt-6 p-3 w-full bg-white dark:bg-[#CAD1D8] text-black justify-center items-center text-sm flex flex-row gap-5"> -->
   <div class=" mt-3 p-3 w-full bg-gray-50 justify-center items-center text-sm flex flex-row gap-5">
            
            <div class="flex-row gap-3 hidden md:flex text-center justify-center items-center">
                <span>
                    <RouterLink to='/about'>About Us</RouterLink>
                </span>
                <span>
                    <RouterLink to='/privacy'>Privacy Policy</RouterLink>
                </span>
                <span>
                    <RouterLink to='/privacy'>Cookie Policy</RouterLink>
                </span>
                <span>
                    <RouterLink to='/privacy'>Billing/Payment T&Cs</RouterLink>
                </span>
                <span> 
                    <RouterLink to='/privacy'>Use of software T&Cs</RouterLink>
                </span>
                <span>
                    <RouterLink to='/FAQ'>FAQs</RouterLink>
                </span>
            </div>
            <p>&copy;2024 ApexTeks all rights reserved.</p>
    </div>
    
    <!-- MINI FOOTER ENDS HERE -->
    <!-- </div> -->
</template>

<script>
import { RouterLink } from 'vue-router';

    export default {
        name: "MiniFooter",
    }
</script>

<style scoped>

</style>