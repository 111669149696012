<template>
  
    <div class="relative flex justify-center items-center p-2">
        <span class="loader border-4 border-gray-200 dark:border-gray-600 dark:opacity-70"></span>
    </div>
  
</template>

<script>
    export default {
        name: "SpinnerComponent",
    }
</script>

<style scoped>
.loader {
    width: 20px;
    height: 20px;
    /* border: 4px solid #dfdfdf; */
    border-bottom-color: transparent;
    border-radius: 50%;
    /* display: inline-block; */
    /* box-sizing: border-box; */
    animation: rotation 1s linear infinite;
    position: absolute;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 
  
</style>