<template>
<SessionExpiredModal v-if="session_expired_modal"/>



<!-- TICKET HELP & SUPPORT MODAL -->
<div v-show="ticket_modal" class=" bg-[rgb(0,0,0,75%)] dark:bg-[rgba(0,0,0,75%)] h-screen w-screen fixed left-0 z-20 flex flex-col justify-center items-center top-0 p-5">
    <div class="md:min-w-[400px] flex flex-col gap-3 bg-white dark:bg-gray-700 p-8 rounded-xl">
            <h1 class="text-2xl font-bold">Submit A Support Ticket</h1>
            <form @submit.prevent="submitSupportEmail" class="flex flex-col gap-5 justify-start mt-5  max-h-[50vh] overflow-y-auto p-5">

                <div class="flex flex-row flex-wrap gap-3 w-full">

                    <div class="flex flex-col gap-3 flex-1">
                        <span>firstname</span>
                        <input v-model="ticket.firstname" required type="text" name="firstname" class="form_input !flex-1" placeholder="john" :disabled="ticket.firstname">
                    </div>
                    <div class="flex flex-col gap-3 flex-1">
                        <span>lastname</span>
                        <input v-model="ticket.lastname" required type="text" name="lsatname" class="form_input !flex-1" placeholder="doe" :disabled="ticket.lastname">
                    </div>
                </div>

                <div class="flex flex-col gap-3">
                    <span>Email</span>
                    <input v-model="ticket.email" required type="email" name="email" class="form_input" placeholder="johndoe@mail.com" :disabled="ticket.email">
                </div>

                
                <div class="flex flex-col gap-3">
                    <span>Category of concern</span>
                    <select v-model="ticket.category" required class="form_input">
                        <option value="" disabled>select category</option>
                        <option v-for="item in ticket_categories" :value="item">{{ item }}</option>
                    </select>
                </div>
              
                <div class="flex flex-col gap-3">
                    <span>How can we help?</span>
                    <textarea required v-model="ticket.description" type="textarea" name="description" class="form_input rounded-md min-h-52 max-h-52 overflow-auto" placeholder="Tell us about questions and concerns..."></textarea>
                </div>
            </form>
            <div class="flex flex-row gap-3 justify-between">
                <button @click="ticket_modal = !ticket_modal" type="button" class="btn !bg-gray-500">Cancel</button>
                <button type="button" @click="submitSupportEmail" class="btn" :disabled="submitting_sp_email">
                    <span v-if="submitting_sp_email">working...</span>
                    <span v-else>Submit</span>
                </button>
            </div>
        </div>
</div>


<!-- ACCOUNT WARNINGS -->
<div :class="KYC.status == 'in progress' ? 'bg-yellow-500':'bg-red-600'" v-if="KYC && KYC.status != 'verified'" class="px-3 p-1 md:p-2  text-sm  text-white  flex flex-row">
    <div class="flex flex-row items-center w-full">
        <i class="bi bi-exclamation-triangle-fill text-xl mr-3"></i>
        <div class="flex flex-row gap-1 items-center justify-center">
            <div>Your account ID verification is <RouterLink to="/in/settings?tab=verification" class=" inline underline font-bold">{{ KYC.status }}</RouterLink> 
                <span v-if="KYC.status == 'pending'">, please 
                    <RouterLink to="/in/settings?tab=verification" class=" inline underline font-bold">Verify now </RouterLink>
             to remove restrictions
            </span>
            </div>
        
        </div>
    </div>
</div>

<div class="bg-transparent border-b dark:border-gray-600">
        <!-- THE DUMMY NAVBAR BELOW SHOWS AS A LOADER ONLY WHEN USER INFO IS NOT AVAILABLE -->
      

        <FullPageLoading  v-if="is_authenticated && !user"/>

        <!-- NAVBAR FOR MAIN APPLICATION AND AUTHENTICATED USERS -->
            <!-- this navbar here displays only on mobile view -->
        
           
            <nav v-if="user">
                <div class="flex flex-row w-full justify-between p-3 items-center">
                    
                    <SiteLogo/>

                    <!-- <div @submit.prevent="searchJob" class="border rounded-md hidden md:flex flex-row items-center h-12 overflow-hidden"> -->
                    
                    <!-- ADD Link to search page to redirect on click -->
                   <!--  <RouterLink to="/client/search">
                        <div class="border rounded-md hidden md:flex flex-row items-center h-12 overflow-hidden dark:border-gray-600">
                            <input type="search" :placeholder="user.role == 'user' ? 'Search for jobs':'Search for Talents'" v-model="job_search" class="p-3 bg-slate-100 border-none form_input">
                            <button @click="handleSearch" class="flex flex-row gap-2 border-l dark:border-gray-600 h-full px-3 items-center hover:bg-slate-50 dark:hover:bg-tz_light_blue">
                                <i class="bi bi-search"></i>
                                <span>Search</span>
                            </button>
                        </div>
                    </RouterLink> -->

                    <div  v-if="user" class="flex flex-row items-center gap-3">
                     
                        <div class="relative">
                            <!-- <div class=" h-3 w-3 flex justify-center items-center text-white  rounded-full bg-red-600 absolute right-[0] top-0 z-10" v-if="notifications && notifications.length > 0"></div> -->
                            
                            <!-- NOTIFICATION MENU AND BELL ICON AS TRIGGER -->
                            <CustomDropdown>
                                <template #trigger>
                                    <div class="relative">
                                        <div v-if="REALTIME_NOTIFICATION.length > 0" class="p-2 !size-5 flex justify-center items-center bg-blue-500 rounded-full absolute z-10 -right-1 -top-1 text-sm text-white">
                                            {{ REALTIME_NOTIFICATION.length }}
                                        </div>
                                        <i class="bi bi-bell border-2 rounded-full h-10 w-10 flex dark:border-gray-400 justify-center items-center relative group/notifications " ></i>
                                    </div>
                                </template>
                                <template #menu>
                                    <div class="  w-[250px] md:min-w-[300px] border bg-white rounded-lg p-1 flex  flex-col gap-2 z-50 dark:bg-[#1F2A36] dark:border-gray-600 ">
                                        <span class="text-center p-2 border-b w-full dark:border-gray-600">Notifications</span>
                                        <div class=" max-h-[250px] overflow-y-auto">
                                            <div v-for="(notification, notify_id) in notifications.slice(0,3)" :key="notify_id" class="flex flex-row p-3 hover:bg-slate-50 rounded-md gap-3 justify-start items-start group/notify dark:hover:bg-tz_light_blue relative">
                                                <div class="text-xl">
                                                    <i class="bi bi-gift-fill" v-if="notification.type == 'contract'"></i>
                                                    <i class="bi bi-briefcase-fill" v-if="notification.type == 'job'"></i>
                                                    <i class="bi bi-person-fill" v-if="notification.type == 'account'"></i>
                                                    <i class="bi bi-cash-stack" v-if="notification.type == 'payment'"></i>
                                                    <i class="bi bi-chat-left-text-fill" v-if="notification.type == 'message'"></i>
                                                    <img src="../assets/images/dot_logo.svg" class=" !size-5" v-if="notification.type == 'platform'"/>
                                                </div>

                                                <!-- LINK FOR USERS -->
                                                <RouterLink class="flex flex-col gap-2 w-[90%]" v-if="user.role == 'user'" :to="`/in${notification.link_url}`" target="_blank">
                                                    <span class="text-sm font-bold">{{  notification.message }}</span>
                                                    <span class="text-sm text-gray-400">{{ realTimeFormat(notification.createdAt)  }}</span>
                                                </RouterLink>

                                                <!-- LINK FOR EMPLOYERS -->
                                                <RouterLink class="flex flex-col gap-2 w-[90%]" v-else :to="`/client${notification.link_url}`" target="_blank">
                                                    <span class="text-sm font-bold">{{  notification.message }}</span>
                                                    <span class="text-sm text-gray-400">{{ realTimeFormat(notification.createdAt)  }}</span>
                                                </RouterLink>

                                                <button @click="markNotificationAsRead(notification._id, notify_id)" class="text-lg font-bold hidden group-hover/notify:block absolute top-2 right-2">
                                                    <span>
                                                        <i class="bi bi-x"></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>

                                        <div class=" text-center text-gray-400 h-[150px] flex flex-col justify-center items-center" v-if="notifications.length == 0">
                                            <i class="bi bi-bell text-3xl"></i>
                                            <p class="text-xl font-bold">You are all caught up</p>
                                            <span>No new notifications</span>
                                        </div>

                                        <RouterLink v-if="user.role == 'user'" to="/in/notifications" class="text-center border-t p-3 dark:border-gray-600">
                                                <span class="text-center text-tz_blue">see all notifications</span>
                                        </RouterLink>
                                        <RouterLink v-else to="/client/notifications" class="text-center border-t p-3 dark:border-gray-600">
                                                <span class="text-center text-tz_blue">see all notifications</span>
                                        </RouterLink>
                                    </div>
                                </template>
                            </CustomDropdown>
                            
                        </div>
                        
                        <i class="bi bi-three-dots-vertical opacity-50"></i>
                        <div class="flex flex-row items-center gap-3 relative group">
                            <!-- USER MENU AND PROFILE IAMGE AS TRIGGER -->
                            <CustomDropdown>
                                <template #trigger>
                                    <img @click="mobile_nav = !mobile_nav" v-if="user.role == 'user' && user.profile.image_url" alt="profile image" :src="user.profile.image_url" class=" rounded-full h-9">
                                    <div @click="mobile_nav = !mobile_nav" v-else class="rounded-full h-9 w-9 flex font-bold justify-center cursor-pointer items-center bg-slate-200 text-slate-600">{{ user.firstname[0] }}{{ user.lastname[0] }}</div>
                                </template>
                                <template #menu>
                                    <UserDropDownMenu 
                                    @openTickets="ticket_modal = !ticket_modal" 
                                    class="" :username="user.firstname + ' ' + user.lastname" :email="user.email"/>
                                </template>
                            </CustomDropdown>
                        </div>
                    </div>
                </div>
            </nav>
            <div v-if="!is_authenticated && !user" class=" bg-white dark:bg-[#131920] border-b border-gray-200  dark:border-gray-600">
                <HomeNavbar />
            </div>
            
</div>


</template>

<!-- briefcase -->
<!-- gift -->
 <!-- person -->
  <!-- cash-stack -->
   <!-- chat-left-text -->
<script>
import { formatToRelativeTime } from '@/utils/dateFormat';
import SiteLogo from './SiteLogo.vue';
import UserDropDownMenu from './UserDropDownMenu.vue';
import axios from 'axios'
import { io } from 'socket.io-client';

import SessionExpiredModal from './SessionExpiredModal.vue'
import HomeNavbar from './HomeNavbar.vue';

import CustomDropdown from '@/components/CustomDropdown.vue'

import Skeleton from 'primevue/skeleton';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import FullPageLoading from '@/components/FullPageLoading.vue'
import HelpAndSupportPageView from '@/views/HelpAndSupportPageView.vue';
import { useToast } from 'vue-toastification';

export default {
    name: "Navbar",
    components: { 
        SiteLogo, 
        UserDropDownMenu, 
        SessionExpiredModal,
        HomeNavbar, 
        CustomDropdown,
        Skeleton,
        SpinnerComponent,
        FullPageLoading,
        HelpAndSupportPageView
    },
    props: {
        type: String,
        jobs_from_search: '',
    },
    data() {
        return {
            toast: useToast(),
            ticket_modal: false,
            nav_visible: false,
            left_nav_open: false,

            notify_menu: false,
            user_menu: false,

            user: null,
            loading: null,

            notifications: '',
            mobile_nav: false,
            headers: {
                Authorization: `JWT ${localStorage.getItem('life-gaurd')}`
            },
            job_search: '',

            is_authenticated: false,

            user_type: '',
            session_expired_modal: false,

            REALTIME_NOTIFICATION: [],

            socket: io(this.api_url.split('/').slice(0, 3).join('/'), { autoConnect: true}),

            email_verified: null,
            sent_verification_mail: false,
            sending_email: false,

            verification_code: '',
            otpInputs: [,,,,,,],
            email_verification_error: '',

            ticket: {
                firstname: '',
                lastname: '',
                email: '',
                category: '',
                description: '',

            },

            ticket_categories: [
                "account", 
                "jobs", 
                "contracts", 
                "taskwatch", 
                "payment_and_withdrawal", 
                "messages", 
                "others"
            ],

            submitting_sp_email: false,
            KYC: '',

            
        };
    },



    methods: {
        checkUserEmailVerification(){
            if(this.user){
                return false
            }
        },

        getNotified(){
            this.socket.on(`notification_${this.user._id}`, (notification) => {
                this.REALTIME_NOTIFICATION.push(notification.message);
                // get user notification and display in notification drop-down
                this.getNotifications();
                console.log("new notification for you: ", notification)
            });
        },
        toggleNavbar(){
            this.left_nav_open = !this.left_nav_open;
            this.$emit('toggle-nav');
        },

        closeDropdown(){
            this.notify_menu = false;
        },

        formatTimestamp(timestamp) {
            const date = new Date(timestamp);
            const options = {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true, // Use 12-hour format
            };
            return date.toLocaleDateString(undefined, options);
        },

        handleInput(index) {
            const nextIndex = index + 1;
            if (this.otpInputs[index] && nextIndex < this.otpInputs.length) {
                // Focus on the next input box if current one is filled
                this.$refs['otpInput' + nextIndex][0].focus();
            }
        },
            
        handleKeyDown(index) {
            this.email_verification_error = '';
            const prevIndex = index - 1;
            if (event.key === 'Backspace' && index > 0 && !this.otpInputs[index]) {
                // Focus on the previous input box if backspace is pressed and current box is empty
                this.$refs['otpInput' + prevIndex][0].focus();
            }
        },

        async submitSupportEmail(){
            try{
                this.submitting_sp_email = true;
                const response = await axios.post(`/tickets/new/${this.user._id}`, this.ticket );
                console.log("response from support email: ", response);
                this.ticket.category = '';
                this.ticket.description = '';
                this.toast.success(response.data.message);
                this.ticket_modal = false;
                this.submitting_sp_email = false;
            }catch(error){
                console.log("error submitting ticket: ", error);
                this.submitting_sp_email = false;
                this.toast.error(error.response.data.message);
            }
        },

        async getUserData(){
            try{
                 // Get the token from localStorage
                const token = localStorage.getItem('life-gaurd');
                const headers = {
                    Authorization: `JWT ${token}`, // Assuming it's a JWT token
                };

                const response = await axios.get(`${this.api_url}/user`, { headers });
                this.user = response.data.user;
                this.user_type = response.data.user.role;
                // if(this.user){
                    this.socket.emit('join', `user_${this.user._id}`);
                    this.getNotified();
                // }

                this.email_verified = response.data.user.email_verified;
                const user = response.data.user;
                this.KYC = response.data.KYC;
                this.ticket.firstname = user.firstname
                this.ticket.lastname = user.lastname
                this.ticket.email = user.email
            }
            catch(error){
                console.log("error from navbar :", error);
                if(error.response.status == '401' && localStorage.getItem('life-gaurd')){
                    
                    localStorage.removeItem('life-gaurd');
                    this.session_expired_modal = true;
                }
            }
        },

        async getNotifications(){
            const headers = this.headers;
            try{
                const response = await axios.get(`${this.api_url}/notifications/unread`, { headers } )
                // console.log("user notifications: ", response);
                this.notifications = response.data.notifications.reverse();

            }catch(error){
                console.log(error);
            }
        },

        async markNotificationAsRead(notification_id, index){
            console.log(notification_id);

            this.notifications.splice(index, 1);
            console.log("removed notifications: ", notification_id, " index: ", index);
            if(this.notifications.length <= 0) {
                this.REALTIME_NOTIFICATION = []
            };

            const headers = this.headers;
            try{
                const response = await axios.post(`${this.api_url}/notifications/${notification_id}/read`, {}, { headers });
                console.log(response);
                this.getNotifications();
            }catch(error){
                console.log("error marking notification as read...", error)
            }
        },

        async searchJob(){
            try{
                const response = await axios.get(`${this.api_url}/jobs/search`, {
                params: {
                    keywords: this.job_search,
                }});

                console.log(response);
                this.jobs_from_search = response.data.jobs;
                this.$router.push('/jobs');

            }catch(error){
                console.log(error)
            }
        },

        async sendVerificationMail(){
            try{
                const email = this.user.email;
                this.sending_email = true;
                const response = await axios.post(`/email/${email}/send`, {}, { headers:this.headers });
                if(response.status == 200){
                    this.sent_verification_mail = true;
                }
                this.sending_email = false;

            }catch(error){
                this.sending_email = false;
                console.log("error sending: ", error);
            }
        },

        async verifyUsingCode(){
            try{
                const verification_code = this.otpInputs.join('');
                const response = await axios.post(`/email/verify`, { verification_code });
                console.log("email verified? ", response);
                window.location.reload();
            }catch(error){
                console.log("error verifying email: ", error);
                this.email_verification_error = error.response.data.message;
            }
        },

        handleSearch() {
            this.$emit('search', {
                keywords: this.job_search
        })},

        logout(){
            localStorage.removeItem("life-gaurd");
            localStorage.removeItem("theme");
            this.$router.push('/login');
            window.location.reload();
        },

        updateTheme(){
            if(localStorage.theme == 'light'){
                // dark_mode(){
                    localStorage.setItem('theme', 'dark');
                    document.documentElement.classList.add("dark");
                    this.theme = true;
                // };
            } else {
                localStorage.setItem('theme', 'light');
                document.documentElement.classList.remove("dark");
                this.theme = false;
            }
        },

        realTimeFormat(time){
            return formatToRelativeTime(time)
        },

    },
    created(){
        this.getUserData();
        this.getNotifications();

        if(localStorage.getItem('life-gaurd')){
            this.is_authenticated = true;
        };

      
        
    },

    mounted() {
      
    },

    unmounted() {
        
    },
}
</script>
<style scoped>
    .nav_btn{
        @apply px-5 py-2 font-bold;
    }

    .nav_link{
        @apply p-3 rounded-md hover:bg-tz_light_blue font-medium hover:font-bold border-b border-opacity-40 border-gray-300;
    }

    .menu_item{
    
    }

    @keyframes skeletonLoading {
    0% {
        opacity: 0.4;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.4;
    }
    }

    .skeleton {
    animation: skeletonLoading 1.5s infinite;
    }
    
</style>